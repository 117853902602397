import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
// import Project from "./components/Projects";
// import Blog from './components/Blog';

function App() {
  return (
    <>
      <Header/>
      <Hero />
      <About />
      {/* <Project />
      <Blog /> */}
    </>
  );
}

export default App;
