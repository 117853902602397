import React from "react";
import HeroImg from "../assets/hero-img.png";

import{
    AiOutlineFacebook,
    AiOutlineInstagram,
    AiOutlineLinkedin
} from "react-icons/ai";

const Hero = () => {
    return (
        <section className="bg-primary px-5 text-white py-32">
            <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
                <div className="hero-info pb-5 md:pb-0">
                    <h1 className="text-4xl lg:text-6xl">
                        Hi, <br/>I am <span className="text-accent">A</span>shish <span className="text-accent">L</span>ama <br/>
                        Software Engineer
                    </h1>

                    <p className="py-5">
                        {/* I am proficient in ....... */}
                    </p>

                    <div className="flex py-5">
                        <a href = "https://www.linkedin.com/in/ashishlama"
                        className="pr-4 inline-block text-accent hover:text-white" target="_blank" rel="noreferrer">
                            {" "}
                            <AiOutlineLinkedin size={40} />{" "}
                        </a>
                        <a href = "https://www.instagram.com/ashish.lama"
                        className="pr-4 inline-block text-accent hover:text-white" target="_blank" rel="noreferrer">
                            {" "}
                            <AiOutlineInstagram size={40} />{" "}
                        </a>
                        <a href = "https://www.facebook.com/ashishl3"
                        className="pr-4 inline-block text-accent hover:text-white" target="_blank" rel="noreferrer">
                            {" "}
                            <AiOutlineFacebook size={40} />{" "}
                        </a>
                        <a href = "/#projects"
                        className="btn bg-accent border-2 border-[#7477FF] text-white px-6 py-3 hover:bg-transparent">
                            See Projects
                        </a>
                    </div>
                    </div>
                <div className="hero-img">
                    <img
                        src={HeroImg}
                        alt= "coding illustration"
                        className="lgw-[80%] ml-auto"
                    />
                </div>
            </div>
        </section>
    )
}

export default Hero;